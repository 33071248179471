import React from 'react'
import { LoadingState } from '../../shared/src/ComponentsV2/common'
import { CheckoutStyledLoading } from './CheckoutLoading.styles'
import Responsive from '../../shared/src/ComponentsV2/HOC/Responsive'


function CartSkeleton({ isMobile = false, }) {
    return (
        <>
            {isMobile ? (<CheckoutStyledLoading>
                <LoadingState cardHeight={48} />
                <LoadingState cardHeight={57} />
                <LoadingState cardHeight={90} />
                <LoadingState cardHeight={28} />
                <LoadingState cardHeight={72} numberOfCards={5} />
            </CheckoutStyledLoading>)
                : (<CheckoutStyledLoading>
                    <LoadingState cardHeight={56} />
                    <div className='checkout-horizontal-container'>
                        <div className='checkout-left'>
                            <LoadingState cardHeight={100} />
                            <LoadingState cardHeight={147} numberOfCards={1} />
                        </div>

                        <div className='checkout-right'>
                            <LoadingState cardHeight={74} />
                            <LoadingState cardHeight={57} />
                            <LoadingState cardHeight={180} />
                        </div>
                    </div>
                </CheckoutStyledLoading>)
            }
        </>

    )
}
export default Responsive(CartSkeleton)