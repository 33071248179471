import styled from 'styled-components'

export const CheckoutStyledLoading = styled.div`
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  
  .checkout-left {
    width: 65%;
    margin-right: 10px;

  }

  .checkout-right {
    width: 35%;
    align-self: flex-end;
    margin-left: 10px;
  }

  .checkout-horizontal-container {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
`
