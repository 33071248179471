import {useCallback, useMemo} from 'react'
import noop from 'lodash/noop'

import {CardProductCardContainer} from './CartProductCard.styles'
import QuantityIcon from './QuantityIcon/QuantityIcon'
import AlertIcon from './AlertIcon/AlertIcon'
import {useInternationalizationContext} from '../../../ComponentsV2/Context/InternationalizationContext'

function CartProductCard(props) {
  const {state = {}, actions = {}} = props
  const {
    id = '',
    image = '',
    name = '',
    originalPrice = 0,
    currentPrice = 0,
    productFor = '',
    concern = '',
    quantity = 0,
    sku = '',
    productURL = '',
    rating = '',
    productRemovedAlert = {},
    rxType = '',
    isFree = '',
    freeText = 'FREE !',
  } = state

  const {
    onAddToCart = noop,
    onRemoveFromCart = noop,
    onCartUpdateProduct = noop,
    onProductNameOrImageClick = noop,
    addRemovedProducts = noop,
    deleteRemovedProducts = noop,
    trackProductRemoved = noop,
    trackAddToCart = noop,
  } = actions

  const {currency} = useInternationalizationContext()

  const showProductRemovedAlert = useMemo(() => {
    return quantity === 0 && !!rxType
  }, [quantity, rxType])

  const removedAlertData = useMemo(() => {
    if (productRemovedAlert[rxType.toLowerCase()]) {
      return productRemovedAlert[rxType.toLowerCase()]
    }

    return {}
  }, [productRemovedAlert, rxType])

  const handleIncreaseQuantity = useCallback(
    () => onCartUpdateProduct(id, sku, quantity + 1),
    [id, onCartUpdateProduct, quantity, sku]
  )

  const handleDecreaseQuantity = useCallback(() => {
    trackProductRemoved({id, name, rxType})
    if (quantity === 1) {
      addRemovedProducts({...props.state, quantity: 0})
      onRemoveFromCart(id, sku, quantity)
      return
    }

    onCartUpdateProduct(id, sku, quantity - 1)
  }, [
    addRemovedProducts,
    id,
    onCartUpdateProduct,
    onRemoveFromCart,
    props.state,
    quantity,
    sku,
    name,
    trackProductRemoved,
    rxType,
  ])

  const handleAddToCart = useCallback(() => {
    trackAddToCart({id, name})
    deleteRemovedProducts(props.state)
    onAddToCart({productId: id, sku})
  }, [
    deleteRemovedProducts,
    id,
    name,
    onAddToCart,
    props.state,
    sku,
    trackAddToCart,
  ])

  const handleProductNameOrImageClicked = useCallback(
    () => onProductNameOrImageClick({name, productURL}),
    [onProductNameOrImageClick, productURL, name]
  )

  return (
    <CardProductCardContainer
      alertBackgroundColor={removedAlertData?.backgroundColor}
      data-is-show-alert={showProductRemovedAlert}
    >
      <div className="product-details-container">
        <div className="product-details">
          <div
            onClick={handleProductNameOrImageClicked}
            className="product-image-and-rating"
          >
            <img
              className="product-image"
              src={image}
              alt="product"
              height={60}
              width={60}
            />
            <span className="rating">
              {rating} <span className="star">★</span>
            </span>
          </div>

          <div className="product-name-container">
            <div
              onClick={handleProductNameOrImageClicked}
              className="product-name"
            >
              {name}
            </div>
            {productFor && (
              <div className="product-for">
                For: <span className="for">{productFor}</span>
              </div>
            )}
          </div>

          <div className="product-price-container">
            <div className="product-price">
              {isFree ? (
                <>
                  <span className="original-price">
                    {currency}
                    {originalPrice}
                  </span>
                  <span className="actual-price">{freeText}</span>
                </>
              ) : (
                <>
                  {currentPrice !== originalPrice && (
                    <span className="original-price">
                      {currency}
                      {originalPrice}
                    </span>
                  )}
                  <span className="actual-price">
                    {currency}
                    {currentPrice}
                  </span>
                </>
              )}
            </div>
            {!isFree && (
              <div className="card-actions">
                {quantity === 0 ? (
                  <button
                    className="card-button"
                    onClick={handleAddToCart}
                    data-is-add-button={quantity === 0}
                  >
                    Add
                  </button>
                ) : (
                  <div className="update-quantity-buttons">
                    <button
                      className="card-button"
                      onClick={handleDecreaseQuantity}
                    >
                      <QuantityIcon type="decrement" />
                    </button>
                    <span className="quantity">{quantity}</span>
                    <button
                      className="card-button"
                      onClick={handleIncreaseQuantity}
                    >
                      <QuantityIcon type="increment" />
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {concern && <div className="product-concern">{concern}</div>}
      </div>

      {showProductRemovedAlert && (
        <div className="removed-alert">
          <AlertIcon iconColor={removedAlertData?.iconColor} />
          <span className="alert-text">{removedAlertData?.text}</span>
        </div>
      )}
    </CardProductCardContainer>
  )
}

export default CartProductCard
