import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import noop from 'lodash/noop'

import {
  useConfigStore,
  useSwipeToBookData,
  useUserStore,
} from '@mosaic-wellness/redux-action-library'
import {EVENT_MAP} from 'src/analytics/eventMap'

interface IUseOutOfStockProductReturnType {
  state: {
    oosOverlayVisible: boolean
    oosDeliveryToastText: string
  }
  actions: {
    handleNotifyMe: () => void
  }
}

interface IUseOutOfStockProductProps {
  productInfo: IProductInfoForOOS
  outOfStockConfig: IOutOfStockConfig
}
interface IProductInfoForOOS {
  availableDays: number | undefined
}

interface IOutOfStockConfig {
  outOfStockToastMessage: string
  outOfStockDeliveryToastText: string
}

export function useOutOfStockProduct(
  props: IUseOutOfStockProductProps
): IUseOutOfStockProductReturnType {
  const {user} = useUserStore()
  const {
    environment: {toast},
    analytics,
  } = useConfigStore()
  const {isLoggedIn = false} = user
  const notifyMeIntent = useRef({intentShown: false, data: {}})
  const {
    actions: {setShowLoginModal = noop},
  } = useSwipeToBookData()
  const {outOfStockConfig, productInfo} = props
  const {outOfStockToastMessage = '', outOfStockDeliveryToastText = ''} =
    outOfStockConfig || {}
  const {availableDays = ''} = productInfo || {}

  const oosDeliveryToastText = useMemo(() => {
    if (availableDays)
      return outOfStockDeliveryToastText.replace(
        '++availableDays++',
        String(availableDays)
      )

    return outOfStockDeliveryToastText.replace('++availableDays++', 'few')
  }, [outOfStockConfig, productInfo])

  const [isOverlayVisible, setIsOverlayVisible] = useState(true)

  const handleNotifyMe = useCallback(() => {
    if (!isLoggedIn) {
      notifyMeIntent.current = {intentShown: true, data: {}}
      setShowLoginModal({isOpen: true, triggeredFrom: ''})
      analytics.trigger(EVENT_MAP.NOTIFY_ME, {})
      return
    }
    toast('success', outOfStockToastMessage)
    analytics.trigger(EVENT_MAP.NOTIFY_ME_DONE, {})
  }, [analytics, isLoggedIn, outOfStockToastMessage, setShowLoginModal, toast])

  useEffect(() => {
    if (isLoggedIn && notifyMeIntent.current.intentShown) {
      analytics.trigger(EVENT_MAP.NOTIFY_ME_DONE, {})
      setTimeout(() => toast('success', outOfStockToastMessage), 1500)
    }
  }, [analytics, isLoggedIn, outOfStockToastMessage, toast])

  useEffect(() => {
    const overlayTimer = setTimeout(() => setIsOverlayVisible(false), 1500)
    return () => {
      clearTimeout(overlayTimer)
    }
  }, [])

  return {
    state: {
      oosOverlayVisible: isOverlayVisible,
      oosDeliveryToastText,
    },
    actions: {
      handleNotifyMe,
    },
  }
}
