import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useExplicitATCTracking} from '../../tracking/useExplicitATCTracking'
import {useUpdateItemToCart} from '../../updateCart/useUpdateItemToCart'
import {CTA_ACTION, useGenericCta} from '../../useGenericCta'
import {HttpClient, useUserStore} from '@mosaic-wellness/redux-action-library'
import {logError} from 'src/utils/logger'
import {BACKEND_BASE_PATH} from 'src/utils/constants/breakPoints'
import isEmpty from 'lodash/isEmpty'
import {useATCBottomSheetStore} from './atcBottomSheet.store'
import {useCheckoutCartPage} from 'src/hooks/checkoutV2/useCheckoutCart'

const useATCBottomSheetParam = () => {
  const axios = HttpClient.getAxiosInstance()
  const {user} = useUserStore()
  const {repeatUser = false} = user || {}
  const {addToCart} = useUpdateItemToCart()
  const {trackAddToCart} = useExplicitATCTracking()
  const {handleCta} = useGenericCta()
  const [checkoutCartState] = useCheckoutCartPage({})

  const {
    cartState: {isCartLoading, isCartFetchedAfterNavigation},
  } = checkoutCartState

  const showAtcBottomSheet = useATCBottomSheetStore(
    (state: any) => state.showAtcBottomSheet
  )
  const setShowAtcBottomSheet = useATCBottomSheetStore(
    (state: any) => state.setShowAtcBottomSheet
  )
  const bottomSheetProducts = useATCBottomSheetStore(
    (state: any) => state.bottomSheetProducts
  )
  const setBottomSheetProducts = useATCBottomSheetStore(
    (state: any) => state.setBottomSheetProducts
  )
  const atcProductImage = useATCBottomSheetStore(
    (state: any) => state.atcProductImage
  )
  const setAtcProductImage = useATCBottomSheetStore(
    (state: any) => state.setAtcProductImage
  )
  const atcBottomSheetSource = useATCBottomSheetStore(
    (state: any) => state.atcBottomSheetSource
  )
  const setAtcBottomSheetSource = useATCBottomSheetStore(
    (state: any) => state.setAtcBottomSheetSource
  )
  const setUseMiniCart = useATCBottomSheetStore(
    (state: any) => state.setUseMiniCart
  )
  const useMiniCart = useATCBottomSheetStore((state: any) => state.useMiniCart)
  const hideGoToCartButton = useATCBottomSheetStore(
    (state: any) => state.hideGoToCartButton
  )
  const setHideGoToCartButton = useATCBottomSheetStore(
    (state: any) => state.setHideGoToCartButton
  )
  const baseProductUrlKey = useATCBottomSheetStore(
    (state: any) => state.baseProductUrlKey
  )
  const setBaseProductUrlKey = useATCBottomSheetStore(
    (state: any) => state.setBaseProductUrlKey
  )

  const isATCForBuyNow = useATCBottomSheetStore(
    (state: any) => state.isATCForBuyNow
  )
  const setIsATCForBuyNow = useATCBottomSheetStore(
    (state: any) => state.setIsATCForBuyNow
  )

  const getATCBottomSheet = useCallback(
    async (urlKey: any) => {
      try {
        const res = await axios.get(
          `${BACKEND_BASE_PATH}page/mwsc/bottom-sheet/${urlKey}?repeatUser=${
            repeatUser ? 1 : 0
          }`
        )
        if (res.data) {
          setBottomSheetProducts(res?.data?.data?.bottomSheetProducts || null)
          return res.data
        }
      } catch (error) {
        logError(error as Error, {
          location: 'FetchBottomFoldData',
        })
      }
    },
    [axios, repeatUser, setBottomSheetProducts]
  )

  const handleAtcBottomSheetOpen = useCallback(() => {
    setShowAtcBottomSheet(true)
  }, [setShowAtcBottomSheet])

  const handleAtcBottomSheetClose = useCallback(() => {
    setBottomSheetProducts({})
    setAtcBottomSheetSource('')
    setShowAtcBottomSheet(false)
    setUseMiniCart(true)
    setHideGoToCartButton(false)
    setBaseProductUrlKey('')
    setIsATCForBuyNow(false)
  }, [
    setAtcBottomSheetSource,
    setBaseProductUrlKey,
    setBottomSheetProducts,
    setHideGoToCartButton,
    setShowAtcBottomSheet,
    setUseMiniCart,
    setIsATCForBuyNow,
  ])

  const handleSetAtcProductImage = useCallback(
    (image) => {
      setAtcProductImage(image)
    },
    [setAtcProductImage]
  )

  const handleSetBottomSheetProducts = useCallback(
    (data) => {
      setBottomSheetProducts(data)
    },
    [setBottomSheetProducts]
  )

  const handleSetAtcBottomSheetSource = useCallback(
    (source) => {
      setAtcBottomSheetSource(source)
    },
    [setAtcBottomSheetSource]
  )

  const handleAddToCartBottomSheet = useCallback(
    (product, paramSource) => {
      const {sku} = product || {}
      trackAddToCart(product, atcBottomSheetSource || paramSource, {
        baseProductUrlKey,
      })
      addToCart({
        sku,
        isMiniCart: useMiniCart ?? true,
        source: 'atcBottomSheet',
      })
    },
    [
      addToCart,
      atcBottomSheetSource,
      baseProductUrlKey,
      trackAddToCart,
      useMiniCart,
    ]
  )

  const onProductCardClick = useCallback(
    (product, paramSource) => {
      handleCta({
        action: CTA_ACTION.PRODUCT_NAVIGATION,
        product: {...product, urlKey: product.urlKey || product.productURL},
        source: atcBottomSheetSource || paramSource,
      })
      handleAtcBottomSheetClose()
    },
    [handleCta, atcBottomSheetSource, handleAtcBottomSheetClose]
  )

  const onGoToCartClick = useCallback(() => {
    handleCta({
      action: CTA_ACTION.SPA_LINK,
      link: '/checkout-v2',
    })
    handleAtcBottomSheetClose()
  }, [handleAtcBottomSheetClose, handleCta])

  const openBottomSheetThroughFetching = useCallback(
    async ({urlKey = '', image = '', source = '', baseProductUrlKey = ''}) => {
      const data = await getATCBottomSheet(urlKey)
      if (!isEmpty(data)) {
        handleSetAtcProductImage(image)
        handleSetAtcBottomSheetSource(source)
        handleAtcBottomSheetOpen()
        setBaseProductUrlKey(baseProductUrlKey)
      }
    },
    [
      getATCBottomSheet,
      handleAtcBottomSheetOpen,
      handleSetAtcBottomSheetSource,
      handleSetAtcProductImage,
      setBaseProductUrlKey,
    ]
  )

  const openBottomSheetWithoutFetching = useCallback(
    ({
      data = {},
      image = '',
      source = '',
      useMiniCart = true,
      hideGoToCartButton = false,
      baseProductUrlKey = '',
    }) => {
      if (hideGoToCartButton) {
        setIsATCForBuyNow(true)
      } else {
        handleAtcBottomSheetOpen()
      }
      handleSetAtcProductImage(image)
      handleSetAtcBottomSheetSource(source)
      handleSetBottomSheetProducts(data)
      setUseMiniCart(useMiniCart)
      setHideGoToCartButton(hideGoToCartButton)
      setBaseProductUrlKey(baseProductUrlKey)
    },
    [
      handleAtcBottomSheetOpen,
      handleSetAtcBottomSheetSource,
      handleSetAtcProductImage,
      handleSetBottomSheetProducts,
      setBaseProductUrlKey,
      setHideGoToCartButton,
      setIsATCForBuyNow,
      setUseMiniCart,
    ]
  )

  useEffect(() => {
    if (isCartFetchedAfterNavigation && !isCartLoading && isATCForBuyNow) {
      handleAtcBottomSheetOpen()
    }
  }, [
    handleAtcBottomSheetOpen,
    isATCForBuyNow,
    isCartFetchedAfterNavigation,
    isCartLoading,
  ])

  const ATCBottomSheetParam = useMemo(() => {
    return [
      {
        showAtcBottomSheet,
        bottomSheetProducts,
        atcProductImage,
        hideGoToCartButton,
      },
      {
        getATCBottomSheet,
        handleAtcBottomSheetOpen,
        handleAtcBottomSheetClose,
        handleSetAtcProductImage,
        handleSetAtcBottomSheetSource,
        handleSetBottomSheetProducts,
        handleAddToCartBottomSheet,
        onProductCardClick,
        onGoToCartClick,
        openBottomSheetThroughFetching,
        openBottomSheetWithoutFetching,
      },
    ]
  }, [
    atcProductImage,
    bottomSheetProducts,
    getATCBottomSheet,
    handleAddToCartBottomSheet,
    handleAtcBottomSheetClose,
    handleAtcBottomSheetOpen,
    handleSetAtcBottomSheetSource,
    handleSetAtcProductImage,
    handleSetBottomSheetProducts,
    onGoToCartClick,
    onProductCardClick,
    openBottomSheetThroughFetching,
    openBottomSheetWithoutFetching,
    showAtcBottomSheet,
    hideGoToCartButton,
  ])

  return ATCBottomSheetParam
}

export default useATCBottomSheetParam
