import {COUNTRY_CODE} from './../../utils/countryCode'
import {IUser} from '@mosaic-wellness/redux-action-library/build/redux.reducers/user'
import {CLEVER_TAP_EVENTS, EVENT_MAP} from '../eventMap'
import {
  processAddToCart,
  processAddToCartPD,
  processBuyNowPd,
  processBuyNowProceedToCheckout,
} from './processors/addToCart'
import {
  processAddAddressClicked,
  processEditAddress,
  processAddressError,
  processAddressViewed,
  processDeleteAddress,
  processDetectUserCity,
} from './processors/detectUserCity'
import mixpanel from 'mixpanel-browser'
import {processConsultStepViewed} from './processors/consult/consultStepViewed'
import {processNotFreeRightNowToggled} from './processors/consult/notFreeToggled'
import {processLanguageChangedInDropdown} from './processors/consult/languageChangedInDropdown'
import {
  processDaBooked,
  processDaError,
  processDaSubmit,
} from './processors/consult/daSubmit'
import {processGetCustomerInfo} from './processors/consult/getCustomerInfo'
import {processImageUpload} from './processors/consult/imageUpload'
import {processAppointmentSummary} from './processors/consult/appointmentSummary'
import {processAppointmentBooked} from './processors/appointmentBooked'
import {processAppointmentSelected} from './processors/appointmentSelected'
import {
  processCancelOrderClicked,
  processMyOrdersViewed,
  processOrderDetailsClicked,
  processCancelOrderClickedWalletPoints,
  processCancelOrder,
  processWalletPayClicked,
} from './processors/order'
import {
  processApplyDiscountClicked,
  processCartUpSellSelection,
  processCartUpSellShow,
  processCartViewed,
  processChangeAddressClicked,
  processCheckoutClicked,
  processCheckoutViewed,
  processCodEligibility,
  processOptionalRxOptIn,
  processOptionalRxOptOut,
  processPayClicked,
  processPaymentFailed,
  processPaymentFailedModalClick,
  processPaymentSelected,
  processPromptPopupClick,
  processPromptPopupSeen,
  processRewardUnlocked,
  processAddressIdSwitched,
  processUPIFailed,
  processGoogleUPIFailed,
  processAppPresenceCheck,
  processAppointmentCardShown,
  processPaymentViewed,
  processWalletMoneyCheckbox,
  processCartUpSellIncentiveButtonCLicked,
  processAddNewMethodCliked,
  processPaymentBannerClicked,
  processPaymentEligibility,
  processNacCartViewed,
  processAppointmentCardClick,
  processWalletCalloutSeen,
  processWalletCalloutClicked,
} from './processors/checkout'
import {processCouponCode} from './processors/couponCode'
import {processConcernClick} from './processors/categoryPage'
import {processDeliveryToastSeen} from './processors/deliveryData'
import {
  processRegimeFilterApplied,
  processRegimeFilterClicked,
} from './processors/regimeFinder'
import {
  processHamburgerClick,
  processNavMenuClicked,
  processSearchClicked,
  processSearchPdp,
} from './processors/nav'
import {
  proccessContactUsViewed,
  processLogin,
  processLoginPageRedirectionButtonClicked,
  processOtp,
  processSignup,
} from './processors/user'
import {processMyProfileView} from './processors/profile'
import {processCalloutClick} from './processors/callout'
import {processPageViews} from './processors/pageViews'
import {
  processPDCarouselSlide,
  processPDDeliveryCheck,
  processPDPView,
  processPDRatingClicked,
  processPDSectionScrollIntoView,
  processPDSectionSectionViewed,
  processPDSwitchClick,
  processVariantClicked,
  processPdpClicked,
  processPDOffersCouponClicked,
  processPDOffersCouponViewed,
  processPDOffersCouponCopied,
  proccessPdFilterClicked,
  processReorderClicked,
  proccessThumbnailClicked,
  processProductExperiments,
  processCTAClicked,
  processCTAViewed,
  proccessViewAllClicked,
  processQuestionAsked,
  processSelfDiagnosisFloatingIslandClosed,
  processNotifyMeClicked,
  processNotifyMeDone,
} from './processors/product'
import {processAssessmentCardClicked} from './processors/assessmentCardClicked'
import {logError} from 'src/utils/logger'
import {processAppointmentConfirm} from './processors/consult/appointmentConfirm'
import {processSendBookSlotDataOnPayButton} from './processors/consult/bookPayloadOnPayClick'
import {
  processReorderUpsellClick,
  processReorderATC,
} from './processors/reorder'
import {
  processTreatmentViewed,
  processTreatmentCustomised,
  processTreatmentAdopted,
} from './processors/treatment/treatmentProcesses'
import {processTopBar} from './processors/consult/topBarEvents'
import {processVideoShowCaseClicked} from './processors/homepage'
import {
  processAddToCartRxCart,
  processCheckoutClickedRxCart,
  processPdpViewed,
  processProductInfoViewed,
  processProductRemovedFromCart,
  processRxCartViewed,
} from './processors/rxCart'
import {processFutureAppointment} from './processors/consult/futureAppointment'
import {IDataLayer} from '../types'
import {
  processSelfAssessmentApiError,
  processSelfAssessmentBasicDetails,
  processSelfAssessmentCategory,
  processSelfAssessmentPlanAppointment,
  processSelfAssessmentQuestions,
  processSelfAssessmentReport,
  processSelfAssessmentSubmitted,
  processSelfAssessmentUserName,
  processSelfAssessmentViewed,
} from './processors/consult/selfAssessmentProcessors'
import {
  processRescheduleConfirmClicked,
  processRescheduleLanguageChanged,
  processRescheduleScreenViewed,
} from './processors/consult/rescheduleScreen'
import {analyticsPush} from '..'
import {
  processImageFlowExit,
  processImageSizeError,
  processImageUploadInitiated,
  processImageUploadSuccess,
  processImageUploadViewed,
  processWhatToUploadClicked,
} from './processors/consult/imageUploadPage'
import {checkIsApp} from 'src/utils/checkIsApp'
import {
  processDaSuccessAppointmentCancelled,
  processDaSuccessDownloadAppClicked,
  processDaSuccessEditAppointmentClicked,
  processDaSuccessRescheduleClicked,
} from './processors/consult/daSuccessScreen'
import {
  processConfirmationScreenV2Viewed,
  processConfirmationScreenV2DownloadAppClicked,
  processConfirmationScreenV2EditAppointmentClicked,
  processConfirmationScreenV2AppointmentCancelled,
  processConfirmationScreenV2RescheduleClicked,
} from './processors/consult/confirmationV2Screen'
import {
  processCommsSectionViewed,
  processCommsConsultStateCard,
  processCommsConsultCardCTAClicked,
  processCommsEditApptClicked,
  processCommsEditApptActionClicked,
  processCommsRescheduleConfirmed,
  processCommsDaClicked,
  processCommsFormClicked,
  processCommsConsultTrackerClicked,
  processCommsPastPrescriptionsClicked,
  processCommsPopUpViewed,
  processCommsPopUpCTAClicked,
  processCommsPopUpClosed,
} from './processors/consult/commsHomePage'

import {
  processSwipeApp,
  processSwipeAppBooked,
  processSwipeAppError,
  processSwipeAppSubmit,
} from './processors/processSwipeToBook'
import {processFloatingIslandNudges} from './processors/floatingIslandNudges'
import {processMiniAssessmentOptionsSelected} from './processors/miniAssessment'

import {
  processPendingOrderAppointmentSeen,
  processPendingOrderRescheduleConfirmed,
  processPendingOrderRescheduleSeen,
  processPendingOrderRescheduleSubmit,
} from './processors/consult/pendingOrderAppointment'
import {
  processAppDownloadBottomSheetSeen,
  processAppDownloadBottomSheetSubmitted,
  processGrowthAppDownloadModalClosed,
  processGrowthAppDownloadModalSeen,
  processGrowthAppDownloadModalSubmitted,
} from './processors/appGrowthModal'
import {
  processCommsDaBottomSheetOpen,
  processCommsDaCategorySelected,
  processCommsDaImageUploadInitiated,
  processCommsDaImageUploadSubmitClicked,
  processCommsDaImageUploadSuccess,
  processCommsDaImageUploadViewed,
  processCommsDaInstantSelected,
  processCommsDaLanguage,
  processCommsDaLanguageChanged,
  processCommsDaQuestion,
  processCommsDaScheduleSubmit,
  processCommsDaViewed,
} from './processors/consult/commsDa'
import {
  processBirthdayTrackerSaved,
  processBirthdayTrackerSeen,
} from './processors/birthdayTracker'
import {
  processBasicDetailsViewed,
  processDetailSubmitted,
  processDetailsQuestionAnswered,
} from './processors/consult/commsBasicDetails'
import {
  processThankYouBranchPurchased,
  processThankYouPageCTAClicked,
  processThankYouPageViewed,
} from './processors/thankYouV2'
import {processThankYouChatQuestion} from './processors/thankYouChat'
import {processDeviceConnectionClicked} from './processors/rookProcessor'
import {
  processChildDetails,
  processChildDetailsBMIViewed,
} from './processors/childDetails'
import {
  processTwidRewardFailure,
  processTwidRewardSelected,
} from './processors/twidRewards'

const country = process.env.NEXT_PUBLIC_COUNTRY || 'IN'
const MIX_PANEL_TOKEN = process.env.NEXT_PUBLIC_MIX_PANEL_TOKEN || ''
const NUDGE_API_KEY = process.env.NEXT_PUBLIC_EXTERNAL_NUDGE_API_KEY || ''
const CLEVER_TAP_TOKEN = process.env.NEXT_PUBLIC_CLEVER_TAP_ID || ''

if (typeof window !== 'undefined') {
  const MIX_PANEL_TOKEN = process.env.NEXT_PUBLIC_MIX_PANEL_TOKEN || ''
  mixpanel.init(MIX_PANEL_TOKEN, {debug: false})
}

export const recordOnCT = (event: string, payload: any, dLayer: IDataLayer) => {
  const {
    isLoggedIn = false,
    emailRegistered = false,
    phoneNumber = '',
    repeatUser = false,
    email = '',
    id = '',
    externalId,
  } = dLayer?.user || {}
  const mandatedPayload = {
    email: email,
    phoneNumber: phoneNumber,
    id: id,
    repeatUser: isLoggedIn ? (repeatUser ? 'Repeat' : 'New') : '-',
  }

  const isApp = checkIsApp()

  try {
    if (isApp) {
      //@ts-ignore
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({
          actions: 'TRIGGER_EVENTS',
          actionData: {
            event: event,
            data: payload,
          },
        })
      )
    } else {
      // @ts-ignore - extracting language set during ssr in _document.tsx
      const languageFromWindowScope = window.__MWSC__?.lang || 'en'
      if (CLEVER_TAP_TOKEN) {
        window.clevertap.event.push(event, {
          ...payload,
          isLoggedIn,
          'Distinct Id': phoneNumber || '',
          'User Type': mandatedPayload.repeatUser,
          lang: languageFromWindowScope,
          ...(isLoggedIn &&
            emailRegistered && {
              $Email: mandatedPayload.email,
              $email: mandatedPayload.email,
            }),
        })
      }
      if (MIX_PANEL_TOKEN) {
        mixpanel.track(event, {
          ...payload,
          isLoggedIn,
          'Distinct Id': phoneNumber || '',
          'User Type': mandatedPayload.repeatUser,
          lang: languageFromWindowScope,
          ...(isLoggedIn &&
            emailRegistered && {
              $Email: mandatedPayload.email,
              $email: mandatedPayload.email,
            }),
          ...(externalId && {
            externalId: externalId,
          }),
        })
      }
    }
  } catch (e) {
    logError(e as Error, {
      source: 'analytics CT',
      location: 'recordOnCT',
    })
  }
}

export const attributeUserForCleverTap = (user: IUser) => {
  const {isLoggedIn = false} = user || {}
  if (isLoggedIn) {
    const {
      repeatUser = false,
      phoneNumber,
      id,
      emailRegistered,
      trackUser,
      email,
      firstName,
      lastName,
      walletPoints,
      externalId,
    } = user || {}
    if (CLEVER_TAP_TOKEN) {
      const userPayload = {
        Phone: `${COUNTRY_CODE}${phoneNumber}`, // Phone (with the country code)
        'Phone No': phoneNumber, // Phone (with the country code)
        USER_ID: id, // String or number
        Identity: country === 'AE' ? id : phoneNumber,
        // optional fields. controls whether the user will be sent email, push etc.
        'MSG-email': true, // Disable email notifications
        'MSG-push': true, // Enable push notifications
        'MSG-sms': true, // Enable sms notifications
        'MSG-whatsapp': true,
        New: 'Y',
        Repeat: repeatUser ? 'Y' : 'N',
        // ? there is a case where user can signup with out email/name in those cases we do not want to track these details to CT
        ...((emailRegistered || trackUser) && {
          Email: email,
          'First Name': firstName,
          'Last Name': lastName,
          Name: `${firstName} ${lastName}`,
          'SignUp Filled': 'N',
          walletPoints: walletPoints,
        }),
      }

      try {
        window.clevertap.onUserLogin.push({
          Site: userPayload,
        })
      } catch (e) {
        logError(e as Error, {
          source: 'analytics CT',
          location: 'attributeUserForCleverTap',
        })
      }

      analyticsPush({updatedClevertapUser: userPayload})
    }

    if (MIX_PANEL_TOKEN) {
      // * MIX_PANEL
      try {
        mixpanel.identify(country === 'AE' ? email : phoneNumber)
        mixpanel.people.set({Phone: phoneNumber})
        mixpanel.people.set({USER_ID: id})
        if (emailRegistered) {
          const fullName = `${firstName} ${lastName}`
          mixpanel.people.set({email: email, $email: email})
          mixpanel.people.set({
            firstName: firstName,
            $first_name: firstName,
          })
          mixpanel.people.set({lastName: lastName, $last_name: lastName})
          mixpanel.people.set({
            name: fullName,
            $name: fullName,
          })
          mixpanel.people.set({walletPoints: walletPoints})
        }
      } catch (e) {
        logError(e as Error, {
          source: 'analytics Mixpanel',
          location: 'attributeUserForCleverTap',
        })
      }
    }
    if (
      NUDGE_API_KEY &&
      // @ts-ignore
      window.__MWSC__?.externalNudge &&
      externalId
      // @ts-ignore
    ) {
      try {
        // @ts-ignore
        window.__MWSC__?.externalNudge?.userIdentifier({
          // @ts-ignore
          properties: {
            nudge_id: externalId,
          },
        })
      } catch (error) {
        // TODO: handle error
      }
    }
  }
}

/**Note: Some Journeys for users are based on some properties set on the user's clevertap profile.
 * For Ex: In Forms flows, users is sent the link of their report and the form-cart on sms. The values for these link is based on some properties in the CT profile
 * These properties are not readily available in the user's CT Profile
 * In such cases, this function can be used to update the user's CT profile, by passing the relevant properties and their values
 */
export const updateUserForClevertap = ({key = '', value = ''}) => {
  if (typeof window !== 'undefined') {
    const updatedClevertapUser = window.customDataLayer.updatedClevertapUser

    if (key && value) {
      window.clevertap.onUserLogin.push({
        Site: {
          ...updatedClevertapUser,
          [key]: value,
        },
      })
    }
  }
}

export const destroySession = () => {
  //@ts-ignore
  if (window.Moengage) {
    //@ts-ignore
    window.Moengage.destroy_session()
  }
}

export const trackForCleverTap = (
  event: string,
  data: any,
  dLayer: Record<string, any>
) => {
  if (CLEVER_TAP_EVENTS[event]) {
    let eventIdentifier = CLEVER_TAP_EVENTS[event]
    let dataToTrack = {}

    if (eventIdentifier) {
      try {
        switch (event) {
          case EVENT_MAP.ADD_ADDRESS_CLICKED: {
            dataToTrack = processAddAddressClicked(data, dLayer)
            break
          }
          case EVENT_MAP.ADDRESS_VIEWED: {
            dataToTrack = processAddressViewed(data, dLayer)
            break
          }
          case EVENT_MAP.ADDRESS_EDIT: {
            dataToTrack = processEditAddress(data, dLayer)
            break
          }
          case EVENT_MAP.ADD_TO_CART_CLICK:
          case EVENT_MAP.CTE_CLICKED: {
            dataToTrack = processAddToCart(data, dLayer)
            break
          }
          case EVENT_MAP.ADD_TO_CART_CLICK_PD: {
            dataToTrack = processAddToCartPD(data, dLayer)
            break
          }

          case EVENT_MAP.APPOINTMENT_BOOKED: {
            dataToTrack = processAppointmentBooked(data, dLayer)
            eventIdentifier = dataToTrack.eventName
            break
          }
          case EVENT_MAP.APPOINTMENT_SELECTED: {
            dataToTrack = processAppointmentSelected(data, dLayer)
            eventIdentifier = dataToTrack.eventName
            break
          }
          case EVENT_MAP.APPLY_DISCOUNT_CLICKED: {
            dataToTrack = processApplyDiscountClicked(data, dLayer)

            break
          }
          case EVENT_MAP.ASSESSMENT_CARD_CLICKED: {
            dataToTrack = processAssessmentCardClicked(data, dLayer)

            break
          }

          case EVENT_MAP.BUY_NOW_CLICK_PD: {
            dataToTrack = processBuyNowPd(data, dLayer)
            break
          }
          case EVENT_MAP.BUY_NOW_CLICK_CHECKOUT: {
            dataToTrack = processBuyNowProceedToCheckout(data, dLayer)
            break
          }

          case EVENT_MAP.CALLOUT_CLICK: {
            dataToTrack = processCalloutClick(data, dLayer)
            break
          }

          case EVENT_MAP.CANCELLATION_REASON_VIEWED:
          case EVENT_MAP.CANCEL_ORDER_CLICKED:
          case EVENT_MAP.CANCEL_ORDER_VIEWED_WALLET_POINTS:
          case EVENT_MAP.CANCEL_ORDER_VIEWED_SHIPPED: {
            dataToTrack = processCancelOrderClicked(data, dLayer)
            break
          }
          case EVENT_MAP.CANCEL_ORDER_CLICKED_WALLET_POINTS: {
            dataToTrack = processCancelOrderClickedWalletPoints(data, dLayer)
            break
          }
          case EVENT_MAP.CANCEL_ORDER_CONFIRMED: {
            dataToTrack = processCancelOrder(data, dLayer)
            break
          }
          case EVENT_MAP.CART_UP_SELL_SELECTION: {
            dataToTrack = processCartUpSellSelection(data, dLayer)
            break
          }
          case EVENT_MAP.CART_UP_SELL_SHOW: {
            dataToTrack = processCartUpSellShow(data, dLayer)
            break
          }
          case EVENT_MAP.CART_UP_SELL_INCENTIVE_BUTTON_CLICKED: {
            dataToTrack = processCartUpSellIncentiveButtonCLicked(data, dLayer)
            break
          }
          case EVENT_MAP.CART_VIEWED: {
            dataToTrack = processCartViewed(data, dLayer)
            break
          }

          case EVENT_MAP.NAC_CART_VIEWED: {
            dataToTrack = processNacCartViewed(data, dLayer)
            break
          }

          case EVENT_MAP.CHANGE_ADDRESS_CLICK: {
            dataToTrack = processChangeAddressClicked(data, dLayer)
            break
          }

          case EVENT_MAP.CHECKOUT_CLICKED: {
            dataToTrack = processCheckoutClicked(data, dLayer)
            break
          }
          case EVENT_MAP.CHECKOUT_VIEWED: {
            dataToTrack = processCheckoutViewed(data, dLayer)
            break
          }

          case EVENT_MAP.APPOINTMENT_CARD_SHOWN: {
            dataToTrack = processAppointmentCardShown(data, dLayer)
            break
          }

          case EVENT_MAP.APPOINTMENT_CARD_CLICK: {
            dataToTrack = processAppointmentCardClick(data)
            break
          }

          case EVENT_MAP.CLICKED_FAILED_MODAL: {
            dataToTrack = processPaymentFailedModalClick(data, dLayer)
            break
          }
          case EVENT_MAP.CONCERN_CLICK: {
            dataToTrack = processConcernClick(data, dLayer)
            break
          }
          case EVENT_MAP.COD_ELIGIBILITY: {
            dataToTrack = processCodEligibility(data, dLayer)
            break
          }
          case EVENT_MAP.COUPON_CODE: {
            dataToTrack = processCouponCode(data, dLayer)
            break
          }
          case EVENT_MAP.DELIVERY_TOAST_SEEN: {
            dataToTrack = processDeliveryToastSeen(data, dLayer)
            break
          }

          case EVENT_MAP.DELIVERY_TOAST_SLIDER_SEEN: {
            dataToTrack = {}
            break
          }

          case EVENT_MAP.DETECT_USER_CITY: {
            const payload = processDetectUserCity(data, dLayer)
            /**
             * ? SPECIAL CASE
             * * when user signsup using only mobile number
             * * we take these details at address step
             * * so pushing remaining data to Profile
             */
            if (payload.trackUser) {
              attributeUserForCleverTap(payload)
            }

            dataToTrack = {...payload}
            break
          }

          case EVENT_MAP.ADDRESS_DELETE: {
            dataToTrack = processDeleteAddress(data, dLayer)
            break
          }

          case EVENT_MAP.ADDRESS_ERROR: {
            dataToTrack = processAddressError(data, dLayer)
            break
          }

          case EVENT_MAP.FILTER_APPLIED_GET_REGIME: {
            const {questions, answers, filters} = processRegimeFilterApplied(
              data,
              dLayer
            )

            questions.forEach((payload: any) => {
              recordOnCT(eventIdentifier, payload, dLayer)
            })

            answers.forEach((payload: any) => {
              recordOnCT(eventIdentifier, payload, dLayer)
            })
            recordOnCT(eventIdentifier, {filters: filters}, dLayer)
            // ! SPECIAL_CASE
            eventIdentifier = ''

            break
          }

          case EVENT_MAP.VIEW_DA: {
            dataToTrack = processConsultStepViewed(event, data, dLayer)
            break
          }

          case EVENT_MAP.VIEW_QUESTIONS: {
            dataToTrack = processConsultStepViewed(event, data, dLayer)
            break
          }

          case EVENT_MAP.CATEGORY_SUBMIT: {
            dataToTrack = {
              Category: data.category || '',
            }
            break
          }

          case EVENT_MAP.VIEW_SCHEDULING_SCREEN: {
            dataToTrack = processConsultStepViewed(event, data, dLayer)
            break
          }

          case EVENT_MAP.NOT_FREE_RIGHT_NOW_TOGGLED_ON: {
            dataToTrack = processNotFreeRightNowToggled(data, dLayer)
            break
          }

          case EVENT_MAP.NOTE_FREE_RIGHT_NOW_TOGGLED_OFF: {
            dataToTrack = processNotFreeRightNowToggled(data, dLayer)
            break
          }

          case EVENT_MAP.LANGUAGE_CHANGED_IN_DROPDOWN: {
            dataToTrack = processLanguageChangedInDropdown(data, dLayer)
            break
          }
          case EVENT_MAP.LOGOUT_SUCCESS: {
            // ! SPECIAL CASE
            eventIdentifier = ''
            // TODO: call moengage Destroy
            destroySession()
            break
          }

          case EVENT_MAP.SCHEDULE_SCREEN_CONFIRMED_CLICKED: {
            dataToTrack = processDaSubmit(data, dLayer)
            break
          }

          case EVENT_MAP.DA_APPOINTMENT_BOOKED: {
            dataToTrack = processDaBooked(data, dLayer)
            break
          }

          case EVENT_MAP.VIEW_GET_CUSTOMER_INFO_SCREEN: {
            dataToTrack = processConsultStepViewed(event, data, dLayer)
            break
          }

          case EVENT_MAP.GET_CUSTOMER_INFO_SUBMIT: {
            dataToTrack = processGetCustomerInfo(data, dLayer)
            break
          }

          case EVENT_MAP.VIEW_IMAGE_UPLOAD: {
            dataToTrack = processConsultStepViewed(event, data, dLayer)
            break
          }

          case EVENT_MAP.IMAGE_UPLOAD_SKIPPED: {
            dataToTrack = processImageUpload(data, dLayer)
            break
          }

          case EVENT_MAP.IMAGE_UPLOADED: {
            dataToTrack = processImageUpload(data, dLayer)
            break
          }

          case EVENT_MAP.DA_EXIT_CLICKED: {
            dataToTrack = processTopBar(data, dLayer)
            break
          }

          case EVENT_MAP.DA_EXIT_CONFIRMED: {
            dataToTrack = processTopBar(data, dLayer)
            break
          }

          case EVENT_MAP.DA_EXIT_CANCELLED: {
            dataToTrack = processTopBar(data, dLayer)
            break
          }

          case EVENT_MAP.DA_BACK_CLICKED: {
            dataToTrack = processTopBar(data, dLayer)
            break
          }

          case EVENT_MAP.FUTURE_APPOINTMENT: {
            dataToTrack = processFutureAppointment(data, dLayer)
            break
          }

          case EVENT_MAP.DA_ERROR: {
            dataToTrack = processDaError(data, dLayer)
            break
          }

          case EVENT_MAP.DA_DOWNLOAD_APP_CLICKED: {
            dataToTrack = processDaSuccessDownloadAppClicked(data, dLayer)
            break
          }

          case EVENT_MAP.DA_EDIT_APPOINTMENT_CLICKED: {
            dataToTrack = processDaSuccessEditAppointmentClicked(data, dLayer)
            break
          }

          case EVENT_MAP.DA_RESCHEDULE_CLICKED: {
            dataToTrack = processDaSuccessRescheduleClicked(data, dLayer)
            break
          }

          case EVENT_MAP.DA_APPOINTMENT_CANCELLED: {
            dataToTrack = processDaSuccessAppointmentCancelled(data, dLayer)
            break
          }

          case EVENT_MAP.APPOINTMENT_CONFIRM_VIEWED: {
            dataToTrack = processAppointmentConfirm(data, dLayer)
            break
          }

          case EVENT_MAP.APPOINTMENT_CONFIRM_IMAGE_UPLOAD: {
            dataToTrack = processAppointmentConfirm(data, dLayer)
            break
          }

          case EVENT_MAP.APPOINTMENT_CONFIRM_CONTINUE_SHOPPING: {
            dataToTrack = processAppointmentConfirm(data, dLayer)
            break
          }

          case EVENT_MAP.TREATMENT_PAGE_VIEWED: {
            dataToTrack = processTreatmentViewed(data, dLayer)
            break
          }

          case EVENT_MAP.CUSTOMISED_PLAN_CLICKED: {
            dataToTrack = processTreatmentViewed(data, dLayer)
            break
          }

          case EVENT_MAP.START_TREATMENT_CLICKED: {
            dataToTrack = processTreatmentAdopted(data, dLayer)
            break
          }

          case EVENT_MAP.TREATMENT_QUESTIONNAIRE_NEXT_CLICKED: {
            dataToTrack = processTreatmentViewed(data, dLayer)
            break
          }

          case EVENT_MAP.FIND_SOLUTION_CLICKED: {
            dataToTrack = processTreatmentCustomised(data, dLayer)
            break
          }

          case EVENT_MAP.VIEW_APPOINTMENT_SUMMARY_TOP_SHEET: {
            dataToTrack = processAppointmentSummary(data, dLayer)
            break
          }

          case EVENT_MAP.VIEW_APPOINTMENT_SUMMARY_SCREEN: {
            dataToTrack = processConsultStepViewed(event, data, dLayer)
            break
          }

          case EVENT_MAP.S_TO_IA_SWITCH: {
            dataToTrack = processAppointmentSummary(data, dLayer)
            break
          }

          case EVENT_MAP.FILTER_CLICKED_GET_REGIME: {
            dataToTrack = processRegimeFilterClicked(data, dLayer)
            break
          }
          case EVENT_MAP.OTP_VERIFIED_LOGIN: {
            dataToTrack = processLogin(data, dLayer)

            attributeUserForCleverTap(dLayer.user)
            break
          }
          case EVENT_MAP.OTP_VERIFIED_SIGN_UP: {
            dataToTrack = processSignup(data, dLayer)
            attributeUserForCleverTap(dLayer.user)
            break
          }

          case EVENT_MAP.MY_ORDERS_VIEWED: {
            dataToTrack = processMyOrdersViewed(data, dLayer)
            break
          }
          case EVENT_MAP.MY_PROFILE_VIEWED: {
            dataToTrack = processMyProfileView(data, dLayer)
            break
          }
          case EVENT_MAP.CONTACT_US_VIEWED: {
            dataToTrack = proccessContactUsViewed(data, dLayer)
            break
          }
          case EVENT_MAP.SUPPORT_INITIATED: {
            dataToTrack = {}
            break
          }

          case EVENT_MAP.NAVBAR_HAMBURGER_CLICK: {
            dataToTrack = processHamburgerClick(data, dLayer)
            break
          }
          case EVENT_MAP.NAVBAR_MENU_CLICKED: {
            dataToTrack = processNavMenuClicked(data, dLayer)
            break
          }
          case EVENT_MAP.OPTIONAL_RX_AVAILABLE: {
            dataToTrack = {}
            break
          }
          case EVENT_MAP.OPTIONAL_RX_OPT_IN: {
            dataToTrack = processOptionalRxOptIn(data, dLayer)
            break
          }
          case EVENT_MAP.OPTIONAL_RX_OPT_OUT: {
            dataToTrack = processOptionalRxOptOut(data, dLayer)
            break
          }
          case EVENT_MAP.ORDER_DETAILS_CLICKED: {
            dataToTrack = processOrderDetailsClicked(data, dLayer)
            break
          }
          case EVENT_MAP.OTP: {
            dataToTrack = processOtp(data, dLayer)
            break
          }
          case EVENT_MAP.PAGE_VIEWED: {
            dataToTrack = processPageViews(data, dLayer)
            if (dataToTrack?.page?.indexOf('/checkout-v2') >= 0) {
              recordOnCT('RCL Checkout Viewed', {}, dLayer)
            }
            break
          }
          case EVENT_MAP.PAY_CLICKED: {
            dataToTrack = processPayClicked(data, dLayer)
            break
          }
          case EVENT_MAP.PAYMENT_FAILED: {
            dataToTrack = processPaymentFailed(data, dLayer)
            break
          }
          case EVENT_MAP.PAYMENT_SELECTED: {
            dataToTrack = processPaymentSelected(data, dLayer)
            break
          }
          case EVENT_MAP.PAYMENT_VIEWED: {
            dataToTrack = processPaymentViewed(data, dLayer)
            break
          }
          case EVENT_MAP.PD_CAROUSEL_SLIDE: {
            dataToTrack = processPDCarouselSlide(data, dLayer)
            break
          }
          case EVENT_MAP.PD_DELIVERY_CHECK: {
            dataToTrack = processPDDeliveryCheck(data, dLayer)
            break
          }
          case EVENT_MAP.PD_SECTION_SCROLLED_INTO_VIEW: {
            dataToTrack = processPDSectionScrollIntoView(data, dLayer)
            break
          }

          case EVENT_MAP.PD_SECTION_VIEWED: {
            dataToTrack = processPDSectionSectionViewed(data, dLayer)
            break
          }

          case EVENT_MAP.PD_SWITCH_CLICK: {
            dataToTrack = processPDSwitchClick(data, dLayer)
            break
          }
          case EVENT_MAP.PDP_VIEW: {
            dataToTrack = processPDPView(data, dLayer)
            break
          }

          case EVENT_MAP.PD_RATING_CLICK: {
            dataToTrack = processPDRatingClicked(data, dLayer)
            break
          }

          case EVENT_MAP.POST_ORDER_FEEDBACK: {
            dataToTrack = {
              ...data,
            }
            break
          }

          case EVENT_MAP.NAVBAR_SEARCH_CLICKED: {
            dataToTrack = processSearchClicked(data, dLayer)
            break
          }
          case EVENT_MAP.SEARCH_PDP: {
            dataToTrack = processSearchPdp(data, dLayer)
            break
          }
          case EVENT_MAP.CTA_CLICKED: {
            dataToTrack = processCTAClicked(data)
            break
          }
          case EVENT_MAP.CTA_CARD_BG_CLICKED: {
            dataToTrack = {...data}
            break
          }
          case EVENT_MAP.CTA_VIEWED: {
            dataToTrack = processCTAViewed(data)
            break
          }
          case EVENT_MAP.PDP_SELF_DIAGNOSIS_FLOATING_ISLAND_CLOSED: {
            dataToTrack = processSelfDiagnosisFloatingIslandClosed(data)
            break
          }
          case EVENT_MAP.BOOK_PAYLOAD_ON_PAY_BUTTON: {
            dataToTrack = processSendBookSlotDataOnPayButton(data, dLayer)
            break
          }

          case EVENT_MAP.NEW_THANKYOU_PAGE: {
            dataToTrack = processThankYouPageViewed(data, dLayer)
            break
          }
          case EVENT_MAP.RX_POPUP_CLICKED: {
            dataToTrack = processPromptPopupClick(data, dLayer)
            break
          }
          case EVENT_MAP.RX_POPUP_SEEN: {
            dataToTrack = processPromptPopupSeen(data, dLayer)
            break
          }
          case EVENT_MAP.VARIANT_CLICKED: {
            dataToTrack = processVariantClicked(data, dLayer)
            break
          }

          case EVENT_MAP.PDP_CLICKED: {
            dataToTrack = processPdpClicked(data, dLayer)
            break
          }

          case EVENT_MAP.REORDER_CLICKED: {
            dataToTrack = processReorderClicked(data, dLayer)
            break
          }

          case EVENT_MAP.THUMBNAIL_IMAGE_CLICKED: {
            dataToTrack = proccessThumbnailClicked(data, dLayer)
            break
          }

          case EVENT_MAP.PRODUCT_FILTER_CLICKED: {
            dataToTrack = proccessPdFilterClicked(data, dLayer)
            break
          }
          case EVENT_MAP.REWARD_UNLOCKED: {
            dataToTrack = processRewardUnlocked(data, dLayer)
            break
          }
          case EVENT_MAP.RR_UP_SHELL: {
            dataToTrack = processReorderUpsellClick(data, dLayer)
            break
          }

          case EVENT_MAP.RR_BN: {
            dataToTrack = processReorderATC(data, dLayer)
            break
          }

          case EVENT_MAP.RR_ATC: {
            dataToTrack = processReorderATC(data, dLayer)
            break
          }

          case EVENT_MAP.USER_FETCHED: {
            attributeUserForCleverTap(data)
            // ! "SPECIAL_CASE"
            eventIdentifier = ''
            break
          }

          case EVENT_MAP.TRUECALLER_SEEN: {
            dataToTrack = {}
            break
          }

          case EVENT_MAP.TRUECALLER_SKIPPED: {
            dataToTrack = {}
            break
          }

          case EVENT_MAP.TRUECALLER_LOGIN: {
            dataToTrack = {}
            break
          }

          case EVENT_MAP.VIDEO_SHOWCASE_CLICKED: {
            dataToTrack = processVideoShowCaseClicked(data, dLayer)
            break
          }

          case EVENT_MAP.VIEW_PRESCRIPTION_CLICKED_RX_CART: {
            dataToTrack = {}
            break
          }

          case EVENT_MAP.PRESCRIPTION_DOWNLOAD: {
            dataToTrack = {}
            break
          }

          case EVENT_MAP.VIEW_RX_CART: {
            dataToTrack = processRxCartViewed(data, dLayer)
            break
          }

          case EVENT_MAP.CHECKOUT_CLICKED_ON_RX_CART: {
            dataToTrack = processCheckoutClickedRxCart(data, dLayer)
            break
          }

          case EVENT_MAP.PRODUCT_REMOVED_FROM_RX_CART: {
            dataToTrack = processProductRemovedFromCart(data, dLayer)
            break
          }

          case EVENT_MAP.ADD_TO_CART_CLICKED_RX_CART: {
            dataToTrack = processAddToCartRxCart(data, dLayer)
            break
          }

          case EVENT_MAP.VIEW_PRODUCT_INFO_RX_CART: {
            dataToTrack = processProductInfoViewed(data, dLayer)
            break
          }

          case EVENT_MAP.VIEW_PDP_FROM_RX_CART: {
            dataToTrack = processPdpViewed(data, dLayer)
            break
          }

          case EVENT_MAP.COUPON_VIEWED: {
            dataToTrack = processPDOffersCouponViewed(data, dLayer)
            break
          }
          case EVENT_MAP.COUPON_CLICKED: {
            dataToTrack = processPDOffersCouponClicked(data, dLayer)
            break
          }
          case EVENT_MAP.COUPON_COPIED: {
            dataToTrack = processPDOffersCouponCopied(data, dLayer)
            break
          }
          case EVENT_MAP.ADDRESS_ID_SWITCHED: {
            dataToTrack = processAddressIdSwitched(data, dLayer)
            break
          }

          case EVENT_MAP.UPI_FAILED: {
            dataToTrack = processUPIFailed(data, dLayer)
            break
          }

          case EVENT_MAP.GOOGLE_UPI_FAILED: {
            dataToTrack = processGoogleUPIFailed(data, dLayer)
            break
          }

          case EVENT_MAP.APP_PRESENCE_CHECK: {
            dataToTrack = processAppPresenceCheck(data, dLayer)
            break
          }

          case EVENT_MAP.SA_VIEWED: {
            dataToTrack = processSelfAssessmentViewed(data, dLayer)
            break
          }

          case EVENT_MAP.SA_STARTED: {
            dataToTrack = processSelfAssessmentViewed(data, dLayer)
            break
          }

          case EVENT_MAP.SA_CHAT_OPEN: {
            dataToTrack = processSelfAssessmentViewed(data, dLayer)
            break
          }

          case EVENT_MAP.SA_NAME_SUBMITTED: {
            dataToTrack = processSelfAssessmentUserName(data, dLayer)
            break
          }

          case EVENT_MAP.SA_CATEGORY_VIEWED: {
            dataToTrack = processSelfAssessmentViewed(data, dLayer)
            break
          }

          case EVENT_MAP.SA_CATEGORY_SUBMITTED: {
            dataToTrack = processSelfAssessmentCategory(data, dLayer)
            break
          }

          case EVENT_MAP.SA_QUESTION: {
            dataToTrack = processSelfAssessmentQuestions(data, dLayer)
            break
          }

          case EVENT_MAP.SA_QUESTION_UNDO: {
            dataToTrack = processSelfAssessmentQuestions(data, dLayer)
            break
          }

          case EVENT_MAP.SA_BASIC_DETAILS: {
            dataToTrack = processSelfAssessmentBasicDetails(data, dLayer)
            break
          }

          case EVENT_MAP.SA_SUBMITTED: {
            dataToTrack = processSelfAssessmentSubmitted(data, dLayer)
            break
          }

          case EVENT_MAP.SA_REPORT_SEEN: {
            dataToTrack = processSelfAssessmentReport(data, dLayer)
            break
          }

          case EVENT_MAP.SA_APPOINTMENT_VIEWED: {
            dataToTrack = processSelfAssessmentReport(data, dLayer)
            break
          }

          case EVENT_MAP.SA_APPOINTMENT_SUBMITTED: {
            dataToTrack = processSelfAssessmentReport(data, dLayer)
            break
          }

          case EVENT_MAP.SA_APPOINTMENT_BOOKED: {
            dataToTrack = processSelfAssessmentReport(data, dLayer)
            break
          }

          case EVENT_MAP.SA_PLAN_CLICKED: {
            dataToTrack = processSelfAssessmentReport(data, dLayer)
            break
          }

          case EVENT_MAP.SA_PLAN_VIEWED: {
            dataToTrack = processSelfAssessmentReport(data, dLayer)
            break
          }

          case EVENT_MAP.SA_PLAN_PRODUCT_REMOVE: {
            dataToTrack = processProductRemovedFromCart(data, dLayer)
            break
          }

          case EVENT_MAP.SA_PLAN_PRODUCT_ADD: {
            dataToTrack = processAddToCartRxCart(data, dLayer)
            break
          }

          case EVENT_MAP.SA_PLAN_PRODUCT_INFO_CARD_CLICKED: {
            dataToTrack = processProductInfoViewed(data, dLayer)
            break
          }

          case EVENT_MAP.SA_PLAN_APPOINTMENT_CARD_SHOWN: {
            dataToTrack = processSelfAssessmentPlanAppointment(data, dLayer)
            break
          }

          case EVENT_MAP.SA_PLAN_TO_PDP: {
            dataToTrack = processPdpViewed(data, dLayer)
            break
          }

          case EVENT_MAP.SA_PLAN_ORDER_CLICKED: {
            dataToTrack = processCheckoutClickedRxCart(data, dLayer)
            break
          }

          case EVENT_MAP.SA_PRESCRIPTION_VIEWED: {
            dataToTrack = {}
            break
          }

          case EVENT_MAP.SA_API_ERROR: {
            dataToTrack = processSelfAssessmentApiError(data, dLayer)
            break
          }

          case EVENT_MAP.UPDATE_CLEVER_TAP_USER: {
            const {key, value} = data
            updateUserForClevertap({key, value})
            eventIdentifier = ''
            break
          }

          case EVENT_MAP.IMAGE_UPLOAD_VIEWED: {
            dataToTrack = processImageUploadViewed(data, dLayer)
            break
          }

          case EVENT_MAP.IMAGE_UPLOAD_INITIATED: {
            dataToTrack = processImageUploadInitiated(data, dLayer)
            break
          }

          case EVENT_MAP.WHAT_TO_UPLOAD_CLICKED: {
            dataToTrack = processWhatToUploadClicked(data, dLayer)
            break
          }

          case EVENT_MAP.IMAGE_SIZE_ERROR: {
            dataToTrack = processImageSizeError(data, dLayer)
            break
          }

          case EVENT_MAP.IMAGE_UPLOAD_SUCCESS: {
            dataToTrack = processImageUploadSuccess(data, dLayer)
            break
          }

          case EVENT_MAP.IMAGE_FLOW_EXIT: {
            dataToTrack = processImageFlowExit(data, dLayer)
            break
          }
          case EVENT_MAP.RESCHEDULE_SCREEN_VIEWED: {
            dataToTrack = processRescheduleScreenViewed(data, dLayer)
            break
          }

          case EVENT_MAP.RESCHEDULE_LANGUAGE_CHANGED: {
            dataToTrack = processRescheduleLanguageChanged(data, dLayer)
            break
          }

          case EVENT_MAP.RESCHEDULE_CONFIRM_CLICKED: {
            dataToTrack = processRescheduleConfirmClicked(data, dLayer)
            break
          }
          case EVENT_MAP.CONFIRMATION_SCREEN_V2_VIEWED: {
            dataToTrack = processConfirmationScreenV2Viewed(data, dLayer)
            break
          }

          case EVENT_MAP.CONFIRMATION_SCREEN_V2_DOWNLOAD_APP_CLICKED: {
            dataToTrack = processConfirmationScreenV2DownloadAppClicked(
              data,
              dLayer
            )
            break
          }

          case EVENT_MAP.CONFIRMATION_SCREEN_V2_EDIT_APPOINTMENT_CLICKED: {
            dataToTrack = processConfirmationScreenV2EditAppointmentClicked(
              data,
              dLayer
            )
            break
          }
          case EVENT_MAP.CONFIRMATION_SCREEN_V2_RESCHEDULE_CLICKED: {
            dataToTrack = processConfirmationScreenV2RescheduleClicked(
              data,
              dLayer
            )
            break
          }
          case EVENT_MAP.CONFIRMATION_SCREEN_V2_APPOINTMENT_CANCELLED: {
            dataToTrack = processConfirmationScreenV2AppointmentCancelled(
              data,
              dLayer
            )
            break
          }
          case EVENT_MAP.COMMS_CONSULT_SECTION_VIEWED: {
            dataToTrack = processCommsSectionViewed(data, dLayer)
            break
          }
          case EVENT_MAP.COMMS_CONSULT_STATE_CARD: {
            dataToTrack = processCommsConsultStateCard(data, dLayer)
            break
          }
          case EVENT_MAP.COMMS_CONSULT_CARD_CTA_CLICKED: {
            dataToTrack = processCommsConsultCardCTAClicked(data, dLayer)
            break
          }
          case EVENT_MAP.COMMS_EDIT_APPT_CLICKED: {
            dataToTrack = processCommsEditApptClicked(data, dLayer)
            break
          }
          case EVENT_MAP.COMMS_EDIT_APPT_ACTION_CLICKED: {
            dataToTrack = processCommsEditApptActionClicked(data, dLayer)
            break
          }
          case EVENT_MAP.COMMS_RESCHEDULE_CONFIRMED: {
            dataToTrack = processCommsRescheduleConfirmed(data, dLayer)
            break
          }
          case EVENT_MAP.COMMS_DA_CLICKED: {
            dataToTrack = processCommsDaClicked(data, dLayer)
            break
          }

          case EVENT_MAP.COMMS_DA_VIEWED: {
            dataToTrack = processCommsDaViewed(data, dLayer)
            break
          }

          case EVENT_MAP.COMMS_DA_CATEGORY_SELECTED: {
            dataToTrack = processCommsDaCategorySelected(data, dLayer)
            break
          }

          case EVENT_MAP.COMMS_DA_QUESTION: {
            dataToTrack = processCommsDaQuestion(data, dLayer)
            break
          }

          case EVENT_MAP.COMMS_DA_LANGUAGE: {
            dataToTrack = processCommsDaLanguage(data, dLayer)
            break
          }

          case EVENT_MAP.COMMS_DA_INSTANT_SELECTED: {
            dataToTrack = processCommsDaInstantSelected(data, dLayer)
            break
          }

          case EVENT_MAP.COMMS_DA_BOTTOM_SHEET_OPEN: {
            dataToTrack = processCommsDaBottomSheetOpen(data, dLayer)
            break
          }

          case EVENT_MAP.COMMS_DA_LANGUAGE_CHANGED: {
            dataToTrack = processCommsDaLanguageChanged(data, dLayer)
            break
          }

          case EVENT_MAP.COMMS_DA_SCHEDULE_SUBMIT: {
            dataToTrack = processCommsDaScheduleSubmit(data, dLayer)
            break
          }

          case EVENT_MAP.COMMS_DA_IMAGE_FLOW_VIEWED: {
            dataToTrack = processCommsDaImageUploadViewed(data, dLayer)
            break
          }

          case EVENT_MAP.COMMS_DA_IMAGE_UPLOAD_INITIATED: {
            dataToTrack = processCommsDaImageUploadInitiated(data, dLayer)
            break
          }

          case EVENT_MAP.COMMS_DA_IMAGE_UPLOAD_SUBMIT_CLICKED: {
            dataToTrack = processCommsDaImageUploadSubmitClicked(data, dLayer)
            break
          }

          case EVENT_MAP.COMMS_DA_IMAGE_UPLOAD_SUCCESS: {
            dataToTrack = processCommsDaImageUploadSuccess(data, dLayer)
            break
          }

          case EVENT_MAP.COMMS_FORM_CLICKED: {
            dataToTrack = processCommsFormClicked(data, dLayer)
            break
          }
          case EVENT_MAP.COMMS_CONSULT_TRACKER_CLICKED: {
            dataToTrack = processCommsConsultTrackerClicked(data, dLayer)
            break
          }
          case EVENT_MAP.COMMS_PAST_PRESCRIPTIONS_CLICKED: {
            dataToTrack = processCommsPastPrescriptionsClicked(data, dLayer)
            break
          }
          case EVENT_MAP.COMMS_POP_UP_VIEWED: {
            dataToTrack = processCommsPopUpViewed(data, dLayer)
            break
          }
          case EVENT_MAP.COMMS_POP_UP_CTA_CLICKED: {
            dataToTrack = processCommsPopUpCTAClicked(data, dLayer)
            break
          }
          case EVENT_MAP.COMMS_POP_UP_CLOSED: {
            dataToTrack = processCommsPopUpClosed(data, dLayer)
            break
          }
          case EVENT_MAP.SWIPE_APPOINTMENT_SUBMIT: {
            dataToTrack = processSwipeAppSubmit(data)
            break
          }
          case EVENT_MAP.SWIPE_APPOINTMENT_BOOKED: {
            dataToTrack = processSwipeAppBooked(data, dLayer)
            break
          }
          case EVENT_MAP.SWIPE_APPOINTMENT_BOOKING_ERROR: {
            dataToTrack = processSwipeAppError(data)
            break
          }
          case EVENT_MAP.SWIPE_TO_BOOK: {
            dataToTrack = processSwipeApp(data)
            break
          }
          case EVENT_MAP.WALLET_RECHARGE_BANNER_CLICKED: {
            dataToTrack = {}
            break
          }

          case EVENT_MAP.WALLET_PAY_CLICK: {
            dataToTrack = processWalletPayClicked(data)
            break
          }

          case EVENT_MAP.PRODUCT_EXPERIMENTS: {
            dataToTrack = processProductExperiments(data, dLayer)
            break
          }
          case EVENT_MAP.FLOATING_ISLAND_NUDGE_CLICKED:
          case EVENT_MAP.FLOATING_ISLAND_NUDGE_VIEWED: {
            dataToTrack = processFloatingIslandNudges(data, dLayer)
            break
          }

          case EVENT_MAP.WALLET_MONEY_CHECKBOX: {
            dataToTrack = processWalletMoneyCheckbox(data, dLayer)
            break
          }
          case EVENT_MAP.PENDING_ORDER_APPOINTMENT: {
            dataToTrack = processPendingOrderAppointmentSeen(data)
            break
          }

          case EVENT_MAP.PENDING_ORDER_RESCHEDULE_SEEN: {
            dataToTrack = processPendingOrderRescheduleSeen(data)
            break
          }

          case EVENT_MAP.PENDING_ORDER_RESCHEDULE_SUBMIT: {
            dataToTrack = processPendingOrderRescheduleSubmit(data)
            break
          }
          case EVENT_MAP.PENDING_ORDER_RESCHEDULE_CONFIRMED: {
            dataToTrack = processPendingOrderRescheduleConfirmed(data)
            break
          }

          case EVENT_MAP.MINI_ASSESSMENT_OPTIONS_SELECTED:
          case EVENT_MAP.MINI_ASSESSMENT_QUESTION_ANSWERED:
            dataToTrack = processMiniAssessmentOptionsSelected(data, dLayer)
            break

          case EVENT_MAP.MINI_ASSESSMENT_QUESTION_VIEWED:
          case EVENT_MAP.MINI_ASSESSMENT_RECOMMENDATIONS_VIEWED:
            dataToTrack = data
            break
          case EVENT_MAP.MINI_ASSESSMENT_COMPLETE: {
            dataToTrack = data
            break
          }

          case EVENT_MAP.GROWTH_APP_DOWNLOAD_MODAL_SEEN:
            dataToTrack = processGrowthAppDownloadModalSeen(data, dLayer)
            break

          case EVENT_MAP.GROWTH_APP_DOWNLOAD_MODAL_CLOSED:
            dataToTrack = processGrowthAppDownloadModalClosed(data)
            break

          case EVENT_MAP.GROWTH_APP_DOWNLOAD_MODAL_SUBMITTED:
            dataToTrack = processGrowthAppDownloadModalSubmitted(data)
            break
          case EVENT_MAP.VIEW_ALL_CLICKED: {
            dataToTrack = proccessViewAllClicked(data)
            break
          }
          case EVENT_MAP.WRITE_REVIEW_CLICKED: {
            dataToTrack = {}
            break
          }
          case EVENT_MAP.REVIEW_SUBMITTED: {
            dataToTrack = {}
            break
          }

          case EVENT_MAP.ASK_QUESTION_CLICKED: {
            dataToTrack = {}
            break
          }
          case EVENT_MAP.QUESTION_ASKED: {
            dataToTrack = processQuestionAsked(data, dLayer)
            break
          }

          case EVENT_MAP.BIRTHDAY_TRACKER_SEEN: {
            dataToTrack = processBirthdayTrackerSeen()
            break
          }

          case EVENT_MAP.BIRTHDAY_TRACKER_ADD_CHILD_CLICKED:
            dataToTrack = processBirthdayTrackerSeen()
            break

          case EVENT_MAP.BIRTHDAY_TRACKER_CHILD_SAVED: {
            dataToTrack = processBirthdayTrackerSaved(data)
            break
          }

          case EVENT_MAP.BIRTHDAY_TRACKER_CHILD_CANCEL: {
            dataToTrack = processBirthdayTrackerSeen()
            break
          }

          case EVENT_MAP.BIRTHDAY_TRACKER_CHILD_CLOSED: {
            dataToTrack = processBirthdayTrackerSeen()
            break
          }
          case EVENT_MAP.LOGIN_PAGE_REDIRECTION_BUTTON_CLICKED: {
            dataToTrack = processLoginPageRedirectionButtonClicked(data)
            break
          }

          case EVENT_MAP.BASIC_DETAILS_VIEWED: {
            dataToTrack = processBasicDetailsViewed(dLayer)
            break
          }

          case EVENT_MAP.BASIC_DETAILS_QUESTION_ANSWERED: {
            dataToTrack = processDetailsQuestionAnswered(data, dLayer)
            break
          }

          case EVENT_MAP.BASIC_DETAILS_DETAIL_SUBMITTED: {
            dataToTrack = processDetailSubmitted(dLayer)
            break
          }

          case EVENT_MAP.LOGIN_PAGE_SKIP_BUTTON_CLICKED: {
            dataToTrack = processLoginPageRedirectionButtonClicked(data)
            break
          }
          case EVENT_MAP.NOTIFY_ME: {
            dataToTrack = processNotifyMeClicked(data, dLayer)
            break
          }

          case EVENT_MAP.NOTIFY_ME_DONE: {
            dataToTrack = processNotifyMeDone(data, dLayer)
            break
          }

          case EVENT_MAP.THANKYOU_PAGE_CTA_CLICK: {
            dataToTrack = processThankYouPageCTAClicked(data, dLayer)
            break
          }

          case EVENT_MAP.APP_DOWNLOAD_BOTTOM_SHEET_SEEN: {
            dataToTrack = processAppDownloadBottomSheetSeen()
            break
          }

          case EVENT_MAP.APP_DOWNLOAD_BOTTOM_SHEET_SUBMITTED: {
            dataToTrack = processAppDownloadBottomSheetSubmitted(data)
            break
          }

          case EVENT_MAP.THANK_YOU_CHAT_QUESTION: {
            dataToTrack = processThankYouChatQuestion(data, dLayer)
            break
          }

          case EVENT_MAP.BRANCH_CHARGED:
          case EVENT_MAP.BRANCH_REPEAT_CHARGED: {
            dataToTrack = processThankYouBranchPurchased(data)
            break
          }

          case EVENT_MAP.ADD_NEW_PAYMENT_METHOD: {
            dataToTrack = processAddNewMethodCliked(data, dLayer)
            break
          }

          case EVENT_MAP.PAYMENT_BANNER_CLICKED: {
            dataToTrack = processPaymentBannerClicked(data, dLayer)
            break
          }

          case EVENT_MAP.PAYMENT_ELIGIBILITY: {
            dataToTrack = processPaymentEligibility(data, dLayer)
            break
          }

          case EVENT_MAP.HOME_STEPS_SEEN:
          case EVENT_MAP.HOME_STEPS_CTA_CLICKED: {
            dataToTrack = data
            break
          }

          case EVENT_MAP.DEVICE_CONNECTION_CLICKED: {
            dataToTrack = processDeviceConnectionClicked(data)
            break
          }
          case EVENT_MAP.CHILD_DETAILS_FILLED: {
            dataToTrack = processChildDetails(data)
            break
          }

          case EVENT_MAP.LANGUAGE_PREFERENCE_VIEWED: {
            dataToTrack = {...data}
            break
          }

          case EVENT_MAP.LANGUAGE_PREFERENCE_SELECTED: {
            dataToTrack = {...data}
            break
          }
          case EVENT_MAP.MINI_ASSESSMENT_LANG_SEEN:
          case EVENT_MAP.MINI_ASSESSMENT_LANG_SELECTED:
            dataToTrack = {...data}
            break

          case EVENT_MAP.CHILD_DETAILS_BMI_VIEWED: {
            dataToTrack = processChildDetailsBMIViewed(data)
            break
          }
          case EVENT_MAP.SELF_ASSESSMENT_DISCOUNT_CLAIMED:
          case EVENT_MAP.SELF_ASSESSMENT_DISCOUNT_PROCEED:
          case EVENT_MAP.SELF_ASSESSMENT_DISCOUNT_SKIPPED:
            dataToTrack = {...data}
            break
          case EVENT_MAP.CONVERSATION_STARTED_SO: {
            dataToTrack = {...data}
            break
          }
          case EVENT_MAP.CONVERSATION_UPDATED: {
            dataToTrack = {...data}
            break
          }
          case EVENT_MAP.CONVERSATION_CALL_UPDATE: {
            dataToTrack = {...data}
            break
          }
          case EVENT_MAP.CONVERSATION_BILLING: {
            break
          }

          case EVENT_MAP.REPORT_COACH_CARD_CLICKED: {
            dataToTrack = {...data}
            break
          }
          case EVENT_MAP.REPORT_COACH_CARD_VIEWED: {
            dataToTrack = {...data}
            break
          }

          case EVENT_MAP.REPORT_COACH_CARD_VIEWED: {
            dataToTrack = {...data}
            break
          }

          case EVENT_MAP.INSTANT_RX_BOTTOM_SHEET_VIEWED: {
            dataToTrack = {...data}
            break
          }

          case EVENT_MAP.NOTIFY_ME_VIEWED: {
            dataToTrack = {...data}
            break
          }
          case EVENT_MAP.DOCTORS_VIEWED: {
            dataToTrack = {...data}
            break
          }
          case EVENT_MAP.DOCTOR_VIEWED: {
            dataToTrack = {...data}
            break
          }
          case EVENT_MAP.CTA_ACTION_CLICKED: {
            dataToTrack = {...data}
            break
          }
          case EVENT_MAP.PAYMENT_SUMARY: {
            dataToTrack = {...data}
            break
          }
          case EVENT_MAP.CONVERSATION_BILLING_VIEWED: {
            dataToTrack = {...data}
            break
          }

          case EVENT_MAP.CONVERSATION_STARTED: {
            dataToTrack = {...data}
            break
          }

          case EVENT_MAP.INSTANT_RX_LANGUAGE_SELECTED: {
            dataToTrack = {...data}
            break
          }

          case EVENT_MAP.INSTANT_RX_IMAGE_UPLOAD_CLICKED: {
            dataToTrack = {...data}
            break
          }

          case EVENT_MAP.UPSELL_MILESTONE_UNLOCKED: {
            dataToTrack = {...data}
            break
          }

          case EVENT_MAP.UPSELL_VIEW_ALL_CLICKED: {
            dataToTrack = {...data}
            break
          }

          case EVENT_MAP.CONSULT_PURCHASE_CLICKED:
          case EVENT_MAP.CONSULT_REPORT_VIEWED:
          case EVENT_MAP.CONSULT_REPORT_SECTION_CLICKED:
          case EVENT_MAP.NOTIFY_OPTIONS_VIEWED:
          case EVENT_MAP.NOTIFY_OPTION_SELECTED:
          case EVENT_MAP.NOTIFY_SLOT_SELECTED:
          case EVENT_MAP.NOTIFY_SLOT_SUBMITTED:
          case EVENT_MAP.NOTIFY_SLOT_VIEWED: {
            dataToTrack = {...data}
            break
          }

          case EVENT_MAP.TWID_PAYMENT_ELIGIBILITY: {
            dataToTrack = {...data}
            break
          }

          case EVENT_MAP.TWID_METHOD_SELECTED: {
            dataToTrack = processTwidRewardSelected(data)
            break
          }

          case EVENT_MAP.TWID_PAYMENT_FAILURE: {
            dataToTrack = processTwidRewardFailure(data)
          }

          case EVENT_MAP.WALLET_CALLOUT_SEEN: {
            dataToTrack = processWalletCalloutSeen(data, dLayer)
            break
          }

          case EVENT_MAP.WALLET_CALLOUT_CLICKED: {
            dataToTrack = processWalletCalloutClicked(data, dLayer)
            break
          }

          default:
            break
        }

        /**
         * ? The eventIdentifier can be reset for some use cases where, we
         * we do not want to trigger a generic event so... for
         * ! "SPECIAL_CASE"
         * resetting eventIdentifier in those case just prevents them from being triggered
         */
        if (!!eventIdentifier) {
          recordOnCT(eventIdentifier, dataToTrack, dLayer)
        }
      } catch (error) {
        logError(error as Error, {
          location: 'CT',
          source: event,
        })
      }
    }
  }
}
