import styled from 'styled-components'

export const CardProductCardContainer = styled.div`
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  background-color: var(--brand-primary-white-color);
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.05);

  .product-details-container {
    padding: 10px;
  }

  .product-details {
    display: flex;
    margin-bottom: 10px;
  }

  .product-image-and-rating {
    height: 60px;
    width: 60px;
    position: relative;
    margin-right: 10px;
    border-radius: 12px;
    overflow: hidden;

    .product-image {
      display: block;
      width: 100%;
      height: auto;
      object-fit: cover;
    }

    .rating {
      position: absolute;
      font-size: 12px;
      padding: 4px;
      border-radius: 12px;
      background-color: var(--brand-primary-color);
      color: var(--brand-primary-white-color);
      font-weight: var(--brand-font-weight-normal);
      bottom: 0;
      right: 0;

      .star {
        color: var(--brand-primary-very-dark-yellow-color);
      }
    }
  }

  .product-name-container {
    .product-name {
      font-size: 14px;
      line-height: 120%;
      font-weight: var(--brand-font-weight-semiBold);
      margin-bottom: 6px;
      max-width: 140px;
    }

    .product-for {
      font-size: 12px;

      .for {
        color: var(--brand-primary-blue-color);
      }
    }
  }

  .product-price-container {
    margin-left: auto;
    display: flex;
    flex-direction: column;

    .product-price {
      margin-left: auto;
    }

    .original-price {
      font-size: 12px;
      line-height: 1;
      color: var(--brand-primary-black-color);
      text-decoration: line-through;
      margin-right: 8px;
      font-weight: var(--brand-font-weight-light);
    }

    .actual-price {
      font-size: 14px;
      line-height: 1;
      font-weight: var(--brand-font-weight-semiBold);
      color: var(--brand-primary-color);
    }

    .card-actions {
      margin-top: auto;
      margin-left: auto;
      display: flex;
    }

    .update-quantity-buttons {
      display: flex;
      align-items: center;
    }

    .card-button {
      height: 25px;
      width: 25px;
      border-radius: 8px;
      outline: 0;
      border: 0;
      font-family: var(--brand-font-family-text);
      background-color: var(--brand-primary-color);
      color: white;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      &[data-is-add-button='true'] {
        padding: 8px;
        width: max-content;
        margin-left: auto;
      }
    }

    .quantity {
      padding: 0 10px;
      font-size: 14px;
      font-weight: var(--brand-font-weight-normal);
    }
  }

  .product-concern {
    width: max-content;
    padding: 4px 6px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: var(--brand-font-weight-normal);
    color: var(--brand-primary-color);
    background-color: var(--category-primary-bg2-color);
  }

  .removed-alert {
    padding: 8px 18px;
    display: flex;
    align-items: center;
    background-color: ${({alertBackgroundColor}) =>
      alertBackgroundColor ? alertBackgroundColor : '#FFF0D9'};

    .alert-text {
      font-size: 12px;
      font-weight: var(--brand-font-weight-normal);
      line-height: 1;
      margin-left: 6px;
    }
  }

  /* Desktop styles */
  @media screen and (min-width: 768px) {
    .product-details-container {
      padding: 16px;
    }

    .product-image-and-rating {
      height: 80px;
      width: 80px;

      .rating {
        font-size: 14px;
      }
    }

    .product-name-container {
      .product-name {
        font-size: 18px;
        max-width: 300px;
      }

      .product-for {
        font-size: 16px;
      }
    }

    .product-price-container {
      .original-price {
        font-size: 16px;
      }

      .actual-price {
        font-size: 18px;
      }

      .quantity {
        font-size: 18px;
      }
    }

    .product-concern {
      font-size: 16px;
      border-radius: 20px;
    }

    .removed-alert {
      padding: 12px 18px;

      .alert-text {
        font-size: 16px;
        margin-left: 8px;
      }

      svg {
        width: 20px;
        height: 18px;
      }
    }
  }
`
